export const environment = {
    production: true,
    appname: "Timetracking-Admin",
    keycloak: {
        issuer: "https://keycloak.activity-time-recording.com/",
        realm: "timetracking",
        clientId: "timetracking.admin"
    },
    api: {
        baseUrl: "https://api.activity-time-recording.com",
    },
    mongoExpress: {
        baseUrl: "https://me.api.activity-time-recording.com"
    },
    glitchTip: {
        dsn: "https://7d14de9ed5474bcb92aa551b32b8115c@glitchtip.activity-time-recording.com/2"
    }
}
